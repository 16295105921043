<template>
	<v-sheet class="recurring-main-listing" style="height: calc(100vh - 88px)">
		<v-layout class="">
			<!-- <v-flex class="py-0">
				<div class="my-2 ml-4 d-flex" style="height: 35px !important">
					<div class="mr-4 py-2 px-3 rounded blue lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="getListing()">All </span>
					</div>
					<div class="mr-4 py-2 px-3 rounded blue lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="statusFilter('Raised')">Raised : </span>
						<v-chip class="px-2 py-1" label color="blue white--text" small>
							{{ count.raisedRecurring }}
						</v-chip>
					</div>
					<div class="mr-4 py-2 px-3 rounded green lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="statusFilter('Paid')">Paid : </span>
						<v-chip class="px-2 py-1" label color="green white--text" small>
							{{ count.paidRecurring }}
						</v-chip>
					</div>
					<div class="mr-4 py-2 px-3 rounded warning lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="statusFilter('Unpaid')">Unpaid : </span>
						<v-chip class="px-2 py-1" label color="warning white--text" small>
							{{ count.unpaidRecurring }}
						</v-chip>
					</div>
					<div class="mr-4 py-2 px-3 rounded cyan lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="statusFilter('Postponed')"
							>Postponed :
						</span>
						<v-chip class="px-2 py-1" label color="cyan white--text" small>
							{{ count.postponedRecurring }}
						</v-chip>
					</div>

				</div>
			</v-flex> -->

			<v-flex class="py-0 my-auto d-flex text-right listing-right">
				<template v-if="selected.length > 0">
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<!-- <v-flex class="d-flex justify-content-end mr-1">
						<div class="mr-2 fw-600 my-auto">Filter By</div>
						<div class="filterTagSelect ml-3">
							<v-autocomplete
								v-model="filter_user"
								:items="userItems"
								:menu-props="{ bottom: true, offsetY: true }"
								prepend-inner-icon="mdi-filter"
								outlined
								hide-details
								class="filterSelect"
								content-class="filterSelectContent"
							>
							</v-autocomplete>
						</div>
					</v-flex> -->

					<div class="py-0 w-20 my-auto">
						<v-select
							v-model="status"
							class="listing-select"
							hide-details
							:disabled="pageLoading"
							:items="listingStatus"
							v-on:change="statusFilterRows($event)"
							solo
						>
							<template v-slot:item="{ item }">
								<v-list-item-content>
									<v-list-item-title v-text="item.text"></v-list-item-title>
									<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-avatar size="26" :color="item.status_color" :text-color="item.textcolor">
										<span>{{ item.status_count }}</span>
									</v-avatar>
								</v-list-item-action>
							</template>
						</v-select>
					</div>
					<v-flex class="d-flex justify-content-end pr-1 mt-3">
						<div class="mr-2 fw-600">Filter By</div>
						<div class="filterTagSelect w-30 mr-2">
							<v-autocomplete
								v-model="ptype"
								:items="billingType"
								item-text="text"
								item-value="value"
								:menu-props="{ bottom: true, offsetY: true }"
								prepend-inner-icon="mdi-tag"
								outlined
								hide-details
								class="filterSelect"
								placeholder="Billing Type"
								content-class="filterSelectContent"
								v-on:change="statusFilterRows($event)"
							>
							</v-autocomplete>
						</div>
					</v-flex>

					<!-- <v-flex class="py-1">
				<v-select
					v-model="ptype"
					class="listing-select"
					hide-details
					:disabled="pageLoading"
					:items="projecttypeList"
					solo
					v-on:change="statusFilterRows($event)"
				>
				</v-select>
			</v-flex> -->

					<!-- <template v-if="internaldata == false">
						<v-btn
							depressed
							:disabled="pageLoading"
							tile
							color="blue darken-4"
							class="text-white my-auto mt-3"
							:to="{
								name: 'create-project',
								query: { ...this.$route.query, t: new Date().getTime() },
							}"
						>
							<v-icon left> mdi-plus </v-icon>
							Create
						</v-btn>
					</template>
					<v-btn
						v-if="internaldata == false"
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white my-auto"
						tile
						v-on:click="routeSetting()"
					>
						<v-icon>mdi-cog</v-icon>
					</v-btn> -->

					<!-- <v-btn
						depressed
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="viewType = !viewType"
					>
						<v-icon v-if="viewType">mdi-view-list</v-icon>
						<v-icon v-else>mdi-grid</v-icon>
					</v-btn> -->

					<!-- <v-btn
						v-if="internaldata == false"
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white my-auto"
						tile
						v-on:click="drawerFilter = true"
					>
						<v-icon>mdi-filter</v-icon>
					</v-btn> -->
					<!-- <v-menu
						v-if="internaldata == false"
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
						class="my-auto"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white my-auto"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-menu</v-icon>
							</v-btn>
						</template>
						<v-list class="py-0 my-auto">
							<template v-for="(item, i) in listingAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu> -->
					<v-menu
						v-if="internaldata == false"
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
						class="my-auto"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white my-auto"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-group my-auto"
							handle=".draggable-drag-icon"
							v-on:change="updateTable('customer')"
						>
							<template v-for="cols in draggableThead" class="my-auto">
								<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										v-bind:value="cols.key"
										:label="cols.name"
										:disabled="cols.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										v-on:change="updateTableVisiblity('project')"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag</v-icon
									>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
				</template>
			</v-flex>
		</v-layout>
		<v-layout class="page-summary show" v-if="false">
			<v-flex
				:class="`summary-details ${item.color} lighten-5`"
				v-for="(item, index) in status_list"
				:key="index"
			>
				<v-icon size="30" :color="item.color">mdi-file-chart-outline</v-icon>
				<div :class="`name ${item.color}--text no-wrap`">{{ item.name }}</div>
				<div :class="`number ${item.color}--text`">{{ item.total_count }}</div>
			</v-flex>
		</v-layout>

		<SearchCriteria
			v-if="search_Enabled()"
			:search-fields="listingFilter"
			search-string="Customer #, Barcode, Name, Email, Phone, Website and Address"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<!-- <template v-if="addvanceFilterAarry.length > 0">
			<v-layout class="btx-search-criteria">
				<v-flex md10>
					<h6>Search Criteria</h6>

					<ul>
						<li>
							Addvance Filter
							<span class="search-text"></span>
						</li>
					</ul>
				</v-flex>
				<v-flex md2 class="text-right">
					<v-btn
						depressed
						color="red lighten-1"
						class="text-white"
						tile
						text
						small
						v-on:click="statusFilterRows('all')"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-flex>
			</v-layout>
		</template> -->

		<Dialog :dialog="export_dialog">
			<template v-slot:title> Import Recurrings </template>
			<template v-slot:body> Export </template>
			<template v-slot:action>
				<v-btn tile depressed class="mr-3" @click="export_dialog = false">Close</v-btn>
				<v-btn tile depressed color="blue darken-4 white--text" class="">Import</v-btn>
			</template>
		</Dialog>

		<AdvanceFilter
			:advanceSearch="drawerFilter"
			v-on:close="drawerFilter = false"
			search-endpoint="project"
			search-title="Projects"
		></AdvanceFilter>

		<template>
			<Table
				type="project"
				delete-endpoint="project/"
				detail-route="project-detail"
				update-route="update-project"
				v-on:reload:content="filterRows"
				delete-note="All transactions of this member will also be deleted."
			></Table>
		</template>

		<ProjectSoeUrl
			v-if="urlsDialog"
			v-on:close="urlsDialog = false"
			:update-invoice-dialog="urlsDialog"
		>
		</ProjectSoeUrl>
		<!--    <Contact
			v-on:success="(updateDialog = false), getListing()"
			v-on:refress="(updateDialog = false),getListing()"
			:updated-data="singleData"
			v-on:close="updateDialog = false"
			:update-dialog="updateDialog"
            :contract-id="contactId"
            :customer-id="relatedId"

		>
		</		> -->

		<!-- <CreateProject
			v-on:success="(projectDialog = false), getListing()"
			v-on:close="projectDialog = false"
			v-on:refress="(projectDialog = false), getListing()"
			:project-dialog="projectDialog"
			:customer-id="relatedId"
			:project-id="projectId"
			internal
		>
		</CreateProject> -->
	</v-sheet>
</template>
<style>
.theme--dark.v-overlay.v-overlay--active {
	z-index: 99 !important;
}
</style>
<script>
import Dialog from "@/view/components/Dialog";
import ListingMixin from "@/core/mixins/listing.mixin";
//import UpdateInvoice from "@/view/module/components/update-invoice";
//import DatePicker from "@/view/components/DatePicker";
//import Contact from "@/view/module/contract/Create-Contract";
/* import CreateProject from "@/view/module/project/CreateProject.vue"; */
// import { ProjectEventBus } from "@/core/lib/leave.form.lib";
import { GET, QUERY } from "@/core/services/store/request.module";
import { SET_TBODY } from "@/core/services/store/listing.module";
import AdvanceFilter from "@/view/pages/leads/AdvanceFilter";
import ProjectSoeUrl from "@/view/module/project/Project-Seo-Url.vue";

export default {
	name: "Contract-listing",
	title: "Listing Contract",
	mixins: [ListingMixin],
	data() {
		return {
			export_dialog: false,
			projectDialog: false,
			singleData: {},
			projectId: 0,
			urlsDialog: false,
			search_query: null,
			actula_date_filter: null,
			drawerFilter: false,
			pageTitle: "Project",
			pageBreadcrumbs: [{ text: "Project", disabled: true }],
			endpoint: "project",
			defaultFilter: {},
			count: {},
			pageLoading: false,
			billingType: [
				{ value: "single_billing", text: "Single Billing" },
				{ value: "multiple_billing", text: "Multiple Billing" },
			],
			DataSearch: null,
			project_status_list: [],
			status: "all",
			/* 		addvanceFilterAarry: [], */
			ptype: "project-types",
		};
	},
	props: {
		relatedId: {
			type: Number,
			default: 0,
		},
		internaldata: {
			type: Boolean,
			default: false,
		},
		updatedData: {
			type: Boolean,
			default: false,
		},
		/*  customerId: {
			type: Number,
			default: 0,
		}, */
	},
	watch: {
		relatedId() {
			this.getListing();
		},
		updatedData() {
			this.getListing();
		},
	},
	methods: {
		referess() {
			window.location.reload();
		},
		create_project() {
			this.projectDialog = true;
		},
		getProjectdata(id) {
			this.projectDialog = true;
			this.projectId = id;
		},
		routeSetting() {
			this.$router.push({
				name: "setting",
				query: {
					tab: "project",
				},
			});
		},
		/* onEsc() {
			this.resetSearch();
		},
		resetSearch() {
			const _this = this;
			_this.$nextTick(() => {
				_this.DataSearch = null;
				this.statusFilterRows();
			});
		}, */
		statusFilterRows(value) {
			//	this.allFiltersStatus(value);
			this.$store
				.dispatch(QUERY, {
					url: `project`,
					data: {
						status: value,
						search: this.DataSearch,
						related_id: this.relatedId,
					},
				})
				.then((data) => {
					this.$store.commit(SET_TBODY, data.tbody);
					/* this.addvanceFilterAarry = []; */
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		allFiltersStatus(value) {
			this.$router.push({
				name: "project",
				query: {
					status: value,
					t: new Date().getTime(),
				},
			});
		},
		/* getprojecttypelist() {
			this.$store
				.dispatch(GET, { url: "project-typelist" })
				.then((data) => {
					this.projecttypeList = data.list;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		}, */
		getprojectstatuslist() {
			this.$store
				.dispatch(GET, { url: "project-status-list" })
				.then((data) => {
					this.project_status_list = data.list;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	components: {
		//RecurringKanban,
		Dialog,
		//UpdateInvoice,
		/* CreateProject, */
		//DatePicker,
		// Contact
		AdvanceFilter,
		ProjectSoeUrl,
	},
	mounted() {
		///this.getprojectstatuslist();
		//this.getprojecttypelist();
		//this.getRecurringStatus();
		//this.getInvoiceCount();
		this.getListing();
		/* ProjectEventBus.$on("updateData", () => {
			this.getListing();
		}); */
	},
};
</script>
